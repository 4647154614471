<template>
  <b-card class="h-100">
    <h6>Minha carteira</h6>
    <div class="row pt-3">
      <div class="col-12 col-md-6">
        <div>
          <div class="mb-3">
            <item-carteira
              titulo="A vencer"
              :valor="aVencer"
              icone="fas fa-calendar-alt"
              cor-background="verde"
              rota="/titulos"
              :rotaFiltroStatus="0"
            />
          </div>
          <div class="mb-3">
            <item-carteira
              titulo="Vencidos"
              :valor="vencidos"
              icone="far fa-clock"
              cor-background="vermelho"
              rota="/titulos"
              :rotaFiltroStatus="1"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <item-carteira
            titulo="Árvores plantadas"
            :valor="valorArvoresPlantadas"
            icone="fas fa-tree"
            :monetario="false"
          />
        </div>
        <div class="mb-3">
          <item-carteira
            titulo="Débitos conta gráfico"
            :valor="saldoContaGrafica"
            icone="fas fa-hand-holding-usd"
          />
        </div>
      </div>

      <div class="col-12 col-md-6">
        <grafico-carteira
          titulo="Limite global"
          titulo-pizza="Total global tomado"
          :valor="limiteUtilizadoGlobal"
          :valor-total="limiteGlobal"
        />
      </div>

      <div class="col-12 col-md-6">
        <grafico-carteira
          titulo="Limite tranche"
          titulo-pizza="Total tranche tomado"
          :valor="limiteUtilizadoTranche"
          :valor-total="limiteTranche"
        />
      </div>
    </div>
  </b-card>
</template>
<script>
import ItemCarteira from "./ItemCarteira.vue";
import GraficoCarteira from "./GraficoCarteira.vue";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import DashboardService from "@/common/services/dashboard/dashboard.service";
import RelatorioService from "@/common/services/relatorio/relatorio.service";
import helpers from "@/common/utils/helpers";

export default {
  components: {
    ItemCarteira,
    GraficoCarteira,
  },
  data: function () {
    return {
      aVencer: 0,
      vencidos: 0,
      limiteGlobal: 0,
      limiteUtilizadoGlobal: 0,
      limiteTranche: 0,
      limiteUtilizadoTranche: 0,
      saldoContaGrafica: 0,
      valorVopPeriodo: 0,
      valorArvoresPlantadas: 0,
      data: null,
      cpfCnpj: null,
    };
  },
  mounted: function () {
    this.cpfCnpj = helpers.getCnpjFromClaim();
    this.getSaldoContaGrafica();
    this.obterPeriodoAnalise();
  },
  methods: {
    obterPeriodoAnalise() {
      this.$store.dispatch(START_LOADING);
      DashboardService.obterPeriodoAnalise(6)
        .then((result) => result?.data?.data)
        .then((data) => {
          if (!data) return;

          this.data = data;

          const {
            aVencer,
            vencidos,
            limiteGlobal,
            limiteUtilizadoGlobal,
            limiteTranche,
            limiteUtilizadoTranche,
            valorVopPeriodo,
            valorArvorePlantadas,
          } = data;

          this.aVencer = aVencer || 0;
          this.vencidos = vencidos || 0;
          this.limiteGlobal = limiteGlobal || 0;
          this.limiteUtilizadoGlobal = limiteUtilizadoGlobal || 0;
          this.limiteTranche = limiteTranche || 0;
          this.limiteUtilizadoTranche = limiteUtilizadoTranche || 0;
          this.valorVopPeriodo = valorVopPeriodo || 0;
          this.valorArvoresPlantadas = Math.round(valorArvorePlantadas || 0);
        })
        .catch((err) => console.log(err))
        .finally(() => this.$store.dispatch(STOP_LOADING));
    },
    getSaldoContaGrafica() {
      this.$store.dispatch(START_LOADING);
      RelatorioService.obterTotalDebito(this.cpfCnpj)
        .then((result) => {
          this.saldoContaGrafica = result.data.data.total;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
