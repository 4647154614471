<template>
  <b-card class="h-100">
    <h6>Meus acessos</h6>
    <div
      class="row"
      v-for="(itens, i) in acessosLiberados"
      :key="`acesso-duplo-${i}`"
    >
      <div
        :class="`col-12 col-md-${itens.length == 2 ? 6 : 12} mt-3`"
        v-for="(acesso, i) in itens"
        :key="`acesso-link-${i}`"
      >
        <div
          class="h-100 bg-valorem-cinza-claro d-flex flex-column align-items-center justify-content-center border-radius acesso"
          @click="redirecionar(acesso.href)"
        >
          <img height="24" :src="acesso.icone" class="mb-2 mt-4" />
          <h6 class="mb-3 text-center">
            {{ $t(`DASHBOARD.${acesso.titulo}`) }}
          </h6>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import helpers from "../../../common/utils/helpers";
export default {
  data: function () {
    return {
      acessos: [
        {
          id: 1,
          icone: require("@/assets/scss/icons/dashboard/arrows-up-down.svg"),
          titulo: "OPERACOES",
          descricao: "OPERACOES_DESC",
          href: "/operacoes",
          habilitado: helpers.validarAcesso("Titulos").visualizar,
        },
        {
          id: 2,
          icone: require("@/assets/scss/icons/dashboard/paper.svg"),
          titulo: "TITULOS",
          descricao: "TITULOS_DESC",
          href: "/titulos",
          habilitado: helpers.validarAcesso("Cnab").visualizar,
        },
        {
          id: 3,
          icone: require("@/assets/scss/icons/dashboard/instruction.svg"),
          titulo: "INSTRUCOES",
          descricao: "INSTRUCOES_DESC",
          href: "/instrucoes",
          habilitado: helpers.validarAcesso("Instrucoes").visualizar,
        },
        {
          id: 4,
          icone: require("@/assets/scss/icons/dashboard/hands.svg"),
          titulo: "PROGRAMACAO_PAGAMENTOS",
          descricao: "PROGRAMACAO_PAGAMENTOS_DESC",
          href: "/programacao",
          habilitado: helpers.validarAcesso("ProgramacaoPagamento").visualizar,
        },
        {
          id: 5,
          icone: require("@/assets/scss/icons/dashboard/download.svg"),
          titulo: "ARQUIVO_RETORNO",
          descricao: "ARQUIVO_RETORNO_DESC",
          href: "/arquivo-retorno",
          habilitado: helpers.validarAcesso("ArquivoRetorno").visualizar,
        },
        {
          id: 6,
          icone: require("@/assets/scss/icons/dashboard/report.svg"),
          titulo: "RELATORIO_CEDENTE",
          descricao: "RELATORIO_CEDENTE_DESC",
          href: "/relatorio",
          habilitado: helpers.validarAcesso("RelatorioCedente").visualizar,
        },
      ],
    };
  },
  methods: {
    redirecionar: function (href) {
      window.location.href = href;
    },
  },
  computed: {
    acessosLiberados: function () {
      let acessosLiberados = this.acessos.filter((el) => el.habilitado);
      let acessos = [];
      for (let index = 0; index < acessosLiberados.length; index += 2) {
        if (acessosLiberados.length < index + 2) {
          acessos.push([acessosLiberados[index]]);
        } else {
          acessos.push([acessosLiberados[index], acessosLiberados[index + 1]]);
        }
      }
      return acessos;
    },
  },
};
</script>
<style lang="scss" scoped>
.border-radius {
  border-radius: 4px;
}

.acesso h6 {
  transition: linear 0.2s;
}
.acesso img {
  transition: linear 0.2s;
}

.acesso:hover h6 {
  transform: scale3d(1.2, 1.2, 1.1);
}
.acesso:hover img {
  transform: scale3d(1.2, 1.2, 1.1);
}
</style>
